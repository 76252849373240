import React, { Component } from "react";

import { convertIndexToDayOfWeek } from "../../helpers/time";

import styles from "./Controls.module.css";

/**
 * The component that contains the controls for the view schedules page.
 */
export default class Controls extends Component {
    render() {
        return (
            <header className={styles.controls} >
                <section className={styles.displayControls}>
                    <button type="button" onClick={() => { this.props.setShowWeek(false) }}>Day</button>
                    <button type="button" onClick={() => { this.props.setShowWeek(true) }}>Week</button>
                </section>
                {(this.props.showWeek && this.props.windowWidth >= 900) ? null :
                    (
                        <section className={styles.dayControls} >
                            <span>
                                <a
                                    href="/#"
                                    className={styles.displayPreviousDay}
                                    aria-label="previous"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.props.setSelectedDayIndex(false)
                                    }}>&lt;</a>
                            </span>
                            <h2 className={styles.displayDayOfWeek}>{convertIndexToDayOfWeek(this.props.selectedDayIndex)}</h2>
                            <span>
                                <a
                                    href="/#"
                                    className={styles.displayNextDay}
                                    aria-label="next"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.props.setSelectedDayIndex(true)
                                    }}>&gt;</a>
                            </span>
                        </section>
                    )
                }
            </header >
        );
    }
}