import React, { Component } from "react";

import logo from "../common/img/user-friends-solid.svg";
import styles from "./index.module.css";

/**
 * The component that contains the splash page.
 */
export default class Splash extends Component {
    render() {
        return (
            <div className={styles.splash}>
                <section>
                    <h1>GroupShare</h1>
                    <img src={logo} title="GroupShare" alt="GroupShare logo" />
                    <p>
                        Making plans around people's everyday life is a struggle that requires
                        everyone to clear their schedules of time conflicts. The problem
                        lies in having to gather the available times for each person in
                        the group and finding an ideal time when everyone can meet. As
                        the group size increases, it can become even more difficult to
                        make plans with friends or family.
                    </p>
                    <p>
                        Add or delete schedules in Manage Schedules. In View Schedules,
                        click anywhere on the schedule table to add an event. Toggle
                        on or off specific schedules to see differences between the
                        schedules. Double-clicking on an event in the schedule table removes
                        it completely. Hovering over an item with a short event duration
                        will show all the details.
                    </p>
                    <button onClick={this.props.dismissSplash}>Get Started</button>
                </section>
                <section>
                    <div></div>
                    <div></div>
                    <div></div>
                </section>
            </div >
        );
    }
}