import React, { Component } from "react";

import Controls from "./Controls";
import ScheduleTable from "./ScheduleTable";
import AddEvent from "./AddEvent";

/**
 * The component that contains the view schedules page.
 */
export default class ViewSchedules extends Component {
    constructor(props) {
        super(props);

        this.state = {
            windowWidth: 0,
            showAddEvent: false,
            showWeek: true,
            selectedDayIndex: 0,
            eventDay: 0,
            eventName: "",
            eventStart: 0,
            eventEnd: 0
        };

        this.handleChange = this.handleChange.bind(this);
        this.updateWindowWidth = this.updateWindowWidth.bind(this);
        this.displayAddEvent = this.displayAddEvent.bind(this);
        this.handleAddEvent = this.handleAddEvent.bind(this);
        this.setShowWeek = this.setShowWeek.bind(this);
        this.setSelectedDayIndex = this.setSelectedDayIndex.bind(this);
    }

    componentDidMount() {
        this.updateWindowWidth();
        window.addEventListener('resize', this.updateWindowWidth);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    /**
     * Handles a change in the components state.
     * 
     * @param {String} field the desired field to change 
     * @param {String} value the desired value to set the field to
     */
    handleChange(field, value) {
        let change = {};
        change[field] = value;
        this.setState(change);
    }

    /**
     * Updates the window's width.
     */
    updateWindowWidth() {
        this.setState({ windowWidth: window.innerWidth });
        if (this.state.windowWidth > 900) {
            this.setShowWeek(true);
        }
    }

    /**
     * Sets whether or not to display the add event modal.
     * 
     * @param {Boolean} display whether or not to display the add event modal
     */
    displayAddEvent(display) {
        this.setState({ showAddEvent: display });
    }

    /**
     * Handles how a schedule event is added.
     *
     * @param {Number} scheduleIndex the desired schedule index
     * @param {Number} dayIndex the desired day index
     * @param {Object} event the new event
     */
    handleAddEvent(scheduleIndex, dayIndex, event) {
        this.props.handleAddEvent(scheduleIndex, dayIndex, event);
        this.displayAddEvent(false);
    }

    /**
     * Sets whether or not to show the week display.
     * 
     * @param {Boolean} display whether or not to show the week display
     */
    setShowWeek(display) {
        this.setState({ showWeek: display });
    }

    /**
     * Increments or decrements the selected day index.
     * 
     * @param {Boolean} increment whether or not to increment or decrement
     */
    setSelectedDayIndex(increment) {
        if (increment && this.state.selectedDayIndex < 6) {
            this.setState((prevState) => { return { selectedDayIndex: prevState.selectedDayIndex + 1 } });
        } else if (!increment && this.state.selectedDayIndex > 0) {
            this.setState((prevState) => { return { selectedDayIndex: prevState.selectedDayIndex - 1 } });
        }
    }

    render() {
        return (
            <>
                <AddEvent
                    handleAddEvent={this.handleAddEvent}
                    displayAddEvent={this.displayAddEvent}
                    shown={this.state.showAddEvent}
                    scheduleNames={this.props.schedules.map((schedule) => { return schedule.name })}
                    handleChange={this.handleChange}
                    eventDay={this.state.eventDay}
                    eventName={this.state.eventName}
                    eventStart={this.state.eventStart}
                    eventEnd={this.state.eventEnd}
                />
                <Controls
                    windowWidth={this.state.windowWidth}
                    showWeek={this.state.showWeek}
                    setShowWeek={this.setShowWeek}
                    selectedDayIndex={this.state.selectedDayIndex}
                    setSelectedDayIndex={this.setSelectedDayIndex}
                />
                <ScheduleTable
                    windowWidth={this.state.windowWidth}
                    displayAddEvent={this.displayAddEvent}
                    showWeek={this.state.showWeek}
                    selectedDayIndex={this.state.selectedDayIndex}
                    schedules={this.props.schedules}
                    handleDeleteEvent={this.props.handleDeleteEvent}
                    handleChange={this.handleChange}
                />
            </>
        );
    }
}