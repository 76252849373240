/**
 * Converts the index into a day of week string representation.
 * 
 * @param {Number} index the desired index
 * 
 * @returns {String} the string representation
 */
export function convertIndexToDayOfWeek(index) {
    switch (index) {
        case 0:
            return "Sunday";
        case 1:
            return "Monday";
        case 2:
            return "Tuesday";
        case 3:
            return "Wednesday";
        case 4:
            return "Thursday";
        case 5:
            return "Friday";
        case 6:
            return "Saturday";
        default:
            return null;
    }
}

/**
 * Converts a valueAsNumber to time in minutes.
 * 
 * @param {Number} valueAsNumber the valueAsNumber from a target
 *
 * @returns {Number} the time in minutes
 */
export function convertValueAsNumberToTimeInMinutes(valueAsNumber) {
    return valueAsNumber / 1000 / 60;
}

/**
 * Converts the time in minutes into a 24 hour string representation.
 * 
 * @param {Number} timeInMinutes the time in minutes
 *
 * @returns {String} the string representation
 */
export function convertMinuteTo24HourMinute(timeInMinutes) {
    let timeHour = splitHour(timeInMinutes);
    let timeMin = splitMinute(timeInMinutes);
    return convertTimeTo24HourString(timeHour, timeMin);
}

/**
 * Convert the time in minutes into a 12 hour string representation.
 * 
 * @param {Number} timeInMinutes the time in minutes
 *
 * @returns {String} the string representation
 */
export function convertMinuteTo12HourMinute(timeInMinutes) {
    let timeHour = splitHour(timeInMinutes);
    let timeMin = splitMinute(timeInMinutes);
    return convertTimeTo12HourString(timeHour, timeMin);
}

/**
 * Converts the split time into a 24 hour string representation.
 * 
 * @param {Number} timeHour the split time in hours
 * @param {Number} timeMin the split time in minutes
 *
 * @returns {String} the string representation
 */
export function convertTimeTo24HourString(timeHour, timeMin) {
    // add leading zero if needed
    let timeHourText = timeHour < 10 ? "0" + timeHour : timeHour;
    let timeMinText = timeMin < 10 ? "0" + timeMin : timeMin;

    return `${timeHourText}:${timeMinText}`;
}

/**
 * Converts the split time into a 12 hour string representation.
 *
 * @param {Number} timeHour the split time in hours
 * @param {Number} timeMin the split time in minutes
 *
 * @returns {String} the string representation
 */
export function convertTimeTo12HourString(timeHour, timeMin) {
    let timePeriodText = timeHour < 12 ? "AM" : "PM";
    timeHour = timeHour % 12;
    timeHour = timeHour === 0 ? 12 : timeHour; // midnight

    // add leading zero if needed
    let timeHourText = timeHour < 10 ? "0" + timeHour : timeHour;
    let timeMinText = timeMin < 10 ? "0" + timeMin : timeMin;

    return `${timeHourText}:${timeMinText} ${timePeriodText}`;
}

/**
 * Splits the time in minutes into ONLY the hours.
 * 
 * @param {Number} timeInMinutes the time in minutes
 *
 * @returns {Number} the split hour time
 */
export function splitHour(timeInMinutes) {
    return Math.floor(timeInMinutes / 60);
}

/**
 * Splits the time in minutes into ONLY the minutes.
 * 
 * @param {Number} timeInMinutes the time in minutes
 *
 * @returns {Number} the split minute time
 */
export function splitMinute(timeInMinutes) {
    return timeInMinutes % 60;
}