import React, { Component } from "react";

import {
    convertValueAsNumberToTimeInMinutes,
    convertMinuteTo24HourMinute
} from "../../helpers/time";

import styles from "./AddEvent.module.css";

/**
 * The component that contains the add event modal for the view schedules page.
 */
export default class AddEvent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedSchedule: 0
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    /**
     * Handles a submission of the form.
     * 
     * @param {Event} e the desired event
     */
    handleSubmit(e) {
        e.preventDefault();

        if (this.props.eventName && (this.props.eventStart < this.props.eventEnd)) {
            let event = {
                name: this.props.eventName,
                start: this.props.eventStart,
                end: this.props.eventEnd
            };
            this.props.handleAddEvent(this.state.selectedSchedule, this.props.eventDay, event);

            // reset
            this.props.handleChange("eventName", "");
            this.props.handleChange("eventStart", 0);
            this.props.handleChange("eventEnd", 0);
        }
    }

    render() {
        let scheduleSelectItems = this.props.scheduleNames.map((name, index) => {
            return (
                <option key={`schedule-select-item-${index}`} value={index}>{name}</option>
            );
        });

        return (
            <section className={this.props.shown ? styles.addEvent : "hidden"} role="dialog" aria-modal="true">
                <div>
                    <h2>Add an Event</h2>
                    <form onSubmit={this.handleSubmit}>
                        <ul>
                            <li>
                                <label htmlFor="selected-schedule">Schedule</label>
                                <select id="selected-schedule" required={this.props.shown}
                                    onChange={(e) => { this.setState({ selectedSchedule: e.target.value }); }}
                                    value={this.state.selectedSchedule}>
                                    {scheduleSelectItems}
                                </select>
                            </li>
                            <li>
                                <label htmlFor="event-day">Event Day</label>
                                <select id="event-day" required={this.props.shown}
                                    onChange={(e) => { this.props.handleChange("eventDay", e.target.value); }}
                                    value={this.props.eventDay}>
                                    <option value="0">Sunday</option>
                                    <option value="1">Monday</option>
                                    <option value="2">Tuesday</option>
                                    <option value="3">Wednesday</option>
                                    <option value="4">Thursday</option>
                                    <option value="5">Friday</option>
                                    <option value="6">Saturday</option>
                                </select>
                            </li>
                            <li>
                                <label htmlFor="event-name">Event Name</label>
                                <input type="text" id="event-name" name="event-name" required={this.props.shown}
                                    onChange={(e) => { this.props.handleChange("eventName", e.target.value); }}
                                    value={this.props.eventName} />
                            </li>

                            <li>
                                <label htmlFor="event-start">Event Start</label>
                                <input type="time" id="event-start" name="event-start" required={this.props.shown}
                                    onChange={(e) => {
                                        this.props.handleChange(
                                            "eventStart",
                                            convertValueAsNumberToTimeInMinutes(e.target.valueAsNumber)
                                        );
                                    }}
                                    value={convertMinuteTo24HourMinute(this.props.eventStart)} />
                            </li>

                            <li>
                                <label htmlFor="event-end">Event End</label>
                                <input type="time" id="event-end" name="event-end" required={this.props.shown}
                                    onChange={(e) => {
                                        this.props.handleChange(
                                            "eventEnd",
                                            convertValueAsNumberToTimeInMinutes(e.target.valueAsNumber)
                                        );
                                    }}
                                    value={convertMinuteTo24HourMinute(this.props.eventEnd)} />
                            </li>
                        </ul>

                        <div>
                            <button type="submit" className="delete" onClick={() => { this.props.displayAddEvent(false) }}>Cancel</button>
                            <button type="submit">Submit</button>
                        </div>
                    </form>
                </div>
            </section>
        );
    }
}