import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import logo from "../common/img/user-friends-solid.svg";
import styles from "./index.module.css";


/**
 * The component that contains the header.
 */
class Header extends Component {
    /**
     * Renders the nav for the view schedule page.
     * 
     * @returns {JSX.Element} the rendered nav
     */
    renderViewScheduleNav() {
        let scheduleList = this.props.schedules.map((schedule, index) => {
            let scheduleId = `schedule-list-item-${index}`;
            return (
                <li key={scheduleId}>
                    <label htmlFor={scheduleId}>{schedule.name}</label>
                    <input type="checkbox" id={scheduleId} name={scheduleId} checked={schedule.active} onChange={() => { this.props.handleScheduleActive(index, !schedule.active); }} />
                </li>
            );
        });
        return (
            <nav>
                <ul>
                    <li id="view-schedules" className={styles.active}>
                        <Link to="/">View Schedules</Link>
                        <ul className="schedule-list">{scheduleList}</ul>
                    </li>
                    <li id="manage-schedules">
                        <Link to="/manage/1">Manage Schedules</Link>
                    </li>
                </ul>
            </nav>
        );
    }

    /**
     * Renders the nav for the manage schedule page.
     *
     * @returns {JSX.Element} the rendered nav
     */
    renderManageScheduleNav() {
        let scheduleList = this.props.schedules.map((schedule, index) => {
            let scheduleId = `schedule-list-item-${index}`;
            return (
                <li key={scheduleId} className={this.props.match.params.index === index ? "selected" : ""}>
                    <Link to={`/manage/${index + 1}`}>{schedule.name}</Link>
                </li>
            );
        });

        return (
            <nav>
                <ul>
                    <li id="view-schedules">
                        <Link to="/">View Schedules</Link>
                    </li>
                    <li id="manage-schedules" className={styles.active}>
                        <Link to="/manage/1">Manage Schedules</Link>
                        <ul className="schedule-list">{scheduleList}</ul>
                    </li>
                    <li id="add-schedule" className={styles.active}>
                        <a href="/#" onClick={(e) => { e.preventDefault(); this.props.handleAddSchedule(); }}>Add Schedule</a>
                    </li>
                </ul>
            </nav>
        );
    }

    render() {
        return (
            <header className={styles.header}>
                <h1>GroupShare</h1>
                <img src={logo} title="GroupShare" alt="GroupShare logo" />
                {(this.props.location.pathname === "/") ? (this.renderViewScheduleNav()) : (this.renderManageScheduleNav())}
            </header>
        );
    }
}

export default withRouter(Header);