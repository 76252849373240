import React, { Component } from "react";

import Event from "./Event";

import { convertMinuteTo12HourMinute } from "../../helpers/time";

import styles from "./ScheduleTable.module.css";

export default class ScheduleTable extends Component {
    /**
     * Generates each schedule row's data.
     * 
     * @returns {Array} the generated row data
     */
    generateScheduleRowData() {
        // generate rows and times
        let scheduleRowData = [];
        for (let i = 0; i < 24 * 2; i++) {
            let timeInMinutes = i * 30;

            let rowData = {
                timeInMinutes: timeInMinutes,
                days: [[], [], [], [], [], [], []]
            };

            scheduleRowData.push(rowData);
        }

        // generate event data for each row
        this.props.schedules.forEach((schedule, scheduleIndex) => {
            if (schedule.active) {
                schedule.days.forEach((day, dayIndex) => {
                    day.forEach((event, eventIndex) => {
                        let eventData = {
                            ...event,
                            scheduleIndex: scheduleIndex,
                            dayIndex: dayIndex,
                            eventIndex: eventIndex
                        };
                        let rowIndex = Math.floor(event.start / 30); // nearest row slot
                        scheduleRowData[rowIndex].days[dayIndex].push(eventData);
                    });
                });
            }
        });

        return scheduleRowData;
    }

    /**
     * Renders the schedule rows.
     * 
     * @param {Boolean} fullWeek whether or not to render the full week
     * 
     * @returns {Array} the generated table rows
     */
    renderScheduleRows(fullWeek) {
        let scheduleRows = this.generateScheduleRowData().map((rowData) => {
            let tds = [];
            if (fullWeek) {
                for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
                    tds.push(this.renderTd(rowData, dayIndex));
                }
            } else {
                tds.push(this.renderTd(rowData, this.props.selectedDayIndex));
            }

            return (
                <tr key={`schedule-row-${rowData.timeInMinutes}`}>
                    <th scope="row">{convertMinuteTo12HourMinute(rowData.timeInMinutes)}</th>
                    {tds}
                </tr>
            );
        });

        return scheduleRows;
    }

    /**
     * Renders a table data cell.
     * 
     * @param {Object} rowData the desired row's data
     * @param {Number} dayIndex the desired day index
     * 
     * @returns {JSX.Element} the rendered td
     */
    renderTd(rowData, dayIndex) {
        let dayEvents = rowData.days[dayIndex].map((event) => {
            return (
                <Event
                    key={`schedule-${event.scheduleIndex}-day-${event.dayIndex}-event-${event.eventIndex}`}
                    scheduleIndex={event.scheduleIndex}
                    eventIndex={event.eventIndex}
                    dayIndex={event.dayIndex}
                    name={event.name}
                    start={event.start}
                    end={event.end}
                    handleDeleteEvent={this.props.handleDeleteEvent} />
            )
        });
        let td = (
            <td
                key={`schedule-row-${rowData.timeInMinutes}-day-${dayIndex}`}
                onClick={() => {
                    this.props.handleChange("eventDay", dayIndex);
                    this.props.handleChange("eventStart", rowData.timeInMinutes);
                    let endTimeInMinutes = rowData.timeInMinutes + 60;
                    let eventEnd = endTimeInMinutes < 1440 ? endTimeInMinutes : 1439;
                    this.props.handleChange("eventEnd", eventEnd);

                    this.props.displayAddEvent(true);
                }}>
                {dayEvents}
            </td>
        );

        return td;
    }

    render() {
        let tableContent;
        if (this.props.showWeek && this.props.windowWidth >= 900) { // week display
            tableContent = (
                <>
                    <thead>
                        <tr>
                            <td></td>
                            <th scope="col">Sunday</th>
                            <th scope="col">Monday</th>
                            <th scope="col">Tuesday</th>
                            <th scope="col">Wednesday</th>
                            <th scope="col">Thursday</th>
                            <th scope="col">Friday</th>
                            <th scope="col">Saturday</th>
                        </tr>
                    </thead>
                    <tbody>{this.renderScheduleRows(true)}</tbody>
                </>
            );
        } else {
            tableContent = (
                <tbody>{this.renderScheduleRows(false)}</tbody>
            );
        }

        return (
            <section className={styles.scheduleContainer}>
                <table className={styles.schedule}>
                    {tableContent}
                </table>
            </section>
        );
    }
}