import React, { Component } from "react";

import styles from "./index.module.css";

/**
 * The component that contains the footer.
 */
export default class Footer extends Component {
    render() {
        return (
            <footer className={styles.footer}>
                <p>GroupShare Project for INFO 340</p>
                <p>
                    GroupShare logo and supporting icons from <a href="https://fontawesome.com/license" target="_blank"
                        rel="noopener noreferrer">Font Awesome</a>
                </p>
                <address>
                    Contact me by <a href="mailto:ixt@uw.edu">email</a>
                </address>
                <p>Ivan Tan</p>
            </footer>
        );
    }
}