import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/database";

/**
 * The component that contains the form for the manage schedules page.
 */
class ManageScheduleForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            importShareCode: "",
            exportShareCode: ""
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.importShareCode = this.importShareCode.bind(this);
        this.exportShareCode = this.exportShareCode.bind(this);
        this.updateScheduleName = this.updateScheduleName.bind(this);
        this.resetForm = this.resetForm.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.index !== prevProps.match.params.index) { // diff schedule
            this.resetForm();
        }
    }

    /**
     * Resets the form's inputs.
     */
    resetForm() {
        // reset state
        this.setState({
            name: "",
            importShareCode: "",
            exportShareCode: ""
        });
    }

    /**
     * Imports a schedule from a Share Code.
     */
    importShareCode() {
        let index = this.props.match.params.index - 1;
        let id = this.state.importShareCode;
        let scheduleRef = firebase.database().ref(`schedules/${id}`);
        scheduleRef.once("value")
            .then((snapshot) => {
                let newSchedule = JSON.parse(snapshot.val());
                this.props.handleUpdateSchedule(index, newSchedule);
                if (this.state.name) { // update if new name
                    this.updateScheduleName();
                }
                this.resetForm();
            });
    }

    /**
     * Exports a schedule into a Share Code.
     */
    exportShareCode() {
        let schedulesRef = firebase.database().ref("schedules");
        let newSchedule = JSON.stringify(this.props.schedule);
        let newScheduleId = schedulesRef.push(newSchedule).key;
        this.setState({ exportShareCode: newScheduleId });
    }

    /**
     * Updates a schedule name.
     */
    updateScheduleName() {
        let index = this.props.match.params.index - 1;
        let newSchedule = this.props.schedule;
        newSchedule.name = this.state.name;
        this.props.handleUpdateSchedule(index, newSchedule);
        this.resetForm();
    }

    /**
     * Handles a change in the form.
     * 
     * @param {String} field the desired field to change
     * @param {String} value the desired value to set the field to
     */
    handleChange(field, value) {
        let change = {};
        change[field] = value;
        this.setState(change);
    }

    /**
     * Handles an update submission in the form.
     */
    handleUpdate() {
        if (this.state.importShareCode) {
            this.importShareCode();
        } else if (this.state.name) {
            this.updateScheduleName();
        }
    }

    /**
     * Handles a delete submission in the form.
     */
    handleDelete() {
        let index = this.props.match.params.index - 1;
        this.props.handleDeleteSchedule(index);
        this.props.history.push('/manage/1');
    }

    render() {
        return (
            <form>
                <ul>
                    <li>
                        <label htmlFor="manage-schedule-name">Schedule Name</label>
                        <input
                            type="text"
                            id="manage-schedule-name"
                            name="manage-schedule-name"
                            placeholder={this.props.schedule.name}
                            onChange={(e) => { this.handleChange("name", e.target.value); }}
                            value={this.state.name}>
                        </input>
                    </li>
                    <li>
                        <label htmlFor="manage-schedule-share-code">Import Share Code</label>
                        <input
                            type="text"
                            id="manage-schedule-share-code"
                            name="manage-schedule-share-code"
                            placeholder="Enter your Share Code to import a schedule!"
                            onChange={(e) => { this.handleChange("importShareCode", e.target.value); }}
                            value={this.state.importShareCode}>
                        </input>
                    </li>
                    <li>
                        <label htmlFor="manage-schedule-share-code">Export Share Code</label>
                        <input
                            type="text"
                            id="manage-schedule-share-code"
                            name="manage-schedule-share-code"
                            placeholder="Click to export this schedule as a Share Code!"
                            onClick={this.exportShareCode}
                            onChange={(e) => { this.handleChange("exportShareCode", e.target.value); }}
                            disabled={this.state.exportShareCode || this.state.importShareCode}
                            value={this.state.exportShareCode}>
                        </input>
                    </li>
                </ul>
                <div>
                    <button
                        type="button"
                        className="delete"
                        onClick={this.handleDelete}>
                        Delete
                    </button>
                    <button
                        type="button"
                        onClick={this.handleUpdate}>
                        Update
                    </button>
                </div>
            </form>
        );
    }
}

export default withRouter(ManageScheduleForm);