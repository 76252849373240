import React, { Component } from "react";
import { withRouter, Redirect } from "react-router";

import ManageScheduleForm from "./ManageScheduleForm";

import styles from "./index.module.css";

/**
 * The component that contains the manage schedules page.
 */
class ManageSchedules extends Component {
    render() {
        let scheduleIndex = this.props.match.params.index - 1;
        if (isNaN(scheduleIndex) || scheduleIndex < 0 || scheduleIndex >= this.props.schedules.length) {
            // is not a number || out of bounds 
            return (
                <Redirect to="/manage/1" />
            );
        }
        return (
            <section className={styles.manageView}>
                <div>
                    <h2>Manage Schedules</h2>
                    <ManageScheduleForm
                        schedule={this.props.schedules[scheduleIndex]}
                        handleUpdateSchedule={this.props.handleUpdateSchedule}
                        handleDeleteSchedule={this.props.handleDeleteSchedule} />
                </div>
            </section>
        );
    }
}

export default withRouter(ManageSchedules);