import React, { Component, createRef } from "react";

import { convertMinuteTo12HourMinute } from "../../helpers/time";

import styles from "./Event.module.css";

/**
 * The component that contains a single event's data.
 */
export default class Event extends Component {
    constructor(props) {
        super(props);

        this.ref = createRef();

        this.state = {
            showDetails: false
        };
    }

    /**
     * Sets whether or not to show the details of the event.
     * 
     * @param {Boolean} display whether or not to display the details
     */
    setShowDetails(display) {
        this.setState({ showDetails: display });
    }

    render() {
        /**
         * Represents the border colors for the events in each schedule.
         */
        const COLORS = ["#E27D60", "#379683", "#05386B", "#C38D9E", "#E8A87C", "#41B3A3"];

        // Start time (y) relative to parent td
        let startTimeTop = `${this.props.start % 30}px`;
        // Duration height (y) relative to parent td
        let durationHeight = `${this.props.end - this.props.start}px`;
        // border color based on scheduleIndex
        let color = COLORS[this.props.scheduleIndex % COLORS.length];

        let startTime = convertMinuteTo12HourMinute(this.props.start);
        let endTime = convertMinuteTo12HourMinute(this.props.end);

        let eventStyles = {
            top: startTimeTop,
            borderColor: color
        };

        if (this.state.showDetails) {
            eventStyles.zIndex = 2;
            eventStyles.height = "";

            let currElement = this.ref.current;
            if (currElement.offsetHeight >= currElement.scrollHeight) { // has height overflow
                eventStyles.height = durationHeight;
            }
        } else {
            eventStyles.zIndex = "";
            eventStyles.height = durationHeight;
        }

        return (
            <article
                ref={this.ref}
                className={styles.event}
                style={eventStyles}
                onMouseEnter={() => { this.setShowDetails(true) }}
                onMouseLeave={() => { this.setShowDetails(false) }}
                onClick={(e) => {
                    // to prevent hitting the event listener on the lower z-indexed td 
                    e.stopPropagation();
                }}
                onDoubleClick={() => {
                    this.props.handleDeleteEvent(
                        this.props.scheduleIndex,
                        this.props.dayIndex,
                        this.props.eventIndex
                    );
                }}
            >
                <h2>{this.props.name}</h2>
                <h3>
                    {`${startTime} - ${endTime}`}
                </h3>
            </article>
        );
    }
}