import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/analytics";

import App from "./App";

import "./index.css";

import CONFIG from "./config.json";

firebase.initializeApp(CONFIG.firebaseConfig);
firebase.analytics();

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <App />
        </Router>
    </React.StrictMode>,
    document.getElementById("root")
);