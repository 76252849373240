import React, { Component } from "react";
import {
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import cloneDeep from "lodash/cloneDeep";

import Header from "./components/Header";
import Splash from "./components/Splash";
import ViewSchedules from "./components/ViewSchedules";
import ManageSchedules from "./components/ManageSchedules";
import Footer from "./components/Footer";

import styles from "./App.module.css";

/**
 * The component that contains the entire scheduling application.
 */
export default class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showSplash: true,
            schedules: []
        };

        this.dismissSplash = this.dismissSplash.bind(this);
        this.handleScheduleActive = this.handleScheduleActive.bind(this);
        this.handleAddSchedule = this.handleAddSchedule.bind(this);
        this.handleUpdateSchedule = this.handleUpdateSchedule.bind(this);
        this.handleDeleteSchedule = this.handleDeleteSchedule.bind(this);
        this.handleAddEvent = this.handleAddEvent.bind(this);
        this.handleDeleteEvent = this.handleDeleteEvent.bind(this);
    }

    componentDidMount() {
        /**
         * Represents the starter schedule that is added when the JSON data files 
         * cannot be accessed.
         */
        const STARTER_SCHEDULE = {
            name: "Schedule #1",
            active: true,
            days: [
                [],
                [{ name: "Event Name", start: "375", end: "450" }],
                [],
                [{ name: "Event Name", start: "370", end: "450" }, { name: "Event Name", start: "600", end: "660" }],
                [],
                [{ name: "Event Name", start: "375", end: "450" }, { name: "Event Name", start: "810", end: "870" }],
                []
            ]
        }

        // Get schedules from localStorage
        let initialSchedules = JSON.parse((localStorage.getItem("schedules") || "[]"));

        if (initialSchedules.length === 0) { // empty
            this.setState(() => {
                let newSchedules = [STARTER_SCHEDULE];
                return { schedules: newSchedules };
            });
        } else {
            this.setState(() => {
                return { schedules: initialSchedules };
            });
        }
    }

    componentDidUpdate() {
        // Update schedules in localStorage
        localStorage.setItem("schedules", JSON.stringify(this.state.schedules));
    }

    /**
     * Dismisses the splash page.
     */
    dismissSplash() {
        this.setState({ showSplash: false });
    }

    /**
     * Handles how the active state of the schedule is set.
     * 
     * @param {Number} index the desired schedule index
     * @param {Boolean} active whether or not the schedule should be active
     */
    handleScheduleActive(index, active) {
        this.setState((prevState) => {
            let newSchedules = prevState.schedules.map((schedule, scheduleIndex) => {
                if (scheduleIndex === index) {
                    schedule.active = active;
                }
                return schedule;
            });

            return { schedules: newSchedules };
        });
    }

    /**
     * Handles how a schedule is added.
     */
    handleAddSchedule() {
        this.setState((prevState) => {
            let newSchedule = {
                name: "New Schedule",
                active: true,
                days: [[], [], [], [], [], [], []]
            };
            let newSchedules = [...prevState.schedules, newSchedule];
            return { schedules: newSchedules };
        });
    }

    /**
     * Handles how a schedule is updated.
     * 
     * @param {Number} index the desired schedule index
     * @param {Object} newSchedule the updated schedule
     */
    handleUpdateSchedule(index, newSchedule) {
        this.setState((prevState) => {
            let newSchedules = prevState.schedules.map((schedule, scheduleIndex) => {
                if (scheduleIndex === index) {
                    return newSchedule;
                }
                return schedule;
            });

            return { schedules: newSchedules };
        });
    }

    /**
     * Handles how a schedule is deleted.
     * 
     * @param {Number} index the desired schedule index
     */
    handleDeleteSchedule(index) {
        if (this.state.schedules.length === 1) { // last schedule
            this.handleAddSchedule();
        }
        this.setState((prevState) => {
            let newSchedules = prevState.schedules.filter((schedule, scheduleIndex) => {
                return scheduleIndex !== index;
            });
            return { schedules: newSchedules };
        });
    }

    /**
     * Handles how a schedule event is added.
     * 
     * @param {Number} scheduleIndex the desired schedule index
     * @param {Number} dayIndex the desired day index
     * @param {Object} event the new event
     */
    handleAddEvent(scheduleIndex, dayIndex, event) {
        this.setState((prevState) => {
            let newSchedules = cloneDeep(prevState.schedules); // to clone nested arrays
            // does not affect prevState, so can use push
            newSchedules[scheduleIndex].days[dayIndex].push(event);

            return { schedules: newSchedules };
        });
    }

    /**
     * Handles how a schedule event is deleted.
     * 
     * @param {Number} scheduleIndex the desired schedule index
     * @param {Number} dayIndex the desired day index
     * @param {Number} eventIndex the desired event index
     */
    handleDeleteEvent(scheduleIndex, dayIndex, eventIndex) {
        this.setState((prevState) => {
            let newSchedules = cloneDeep(prevState.schedules); // to clone nested arrays
            // does not affect prevState, so can use splice
            newSchedules[scheduleIndex].days[dayIndex].splice(eventIndex, 1);

            return { schedules: newSchedules };
        });
    }

    render() {
        return (
            <div className={styles.appContainer}>
                {this.state.showSplash ?
                    (
                        <main>
                            <Splash dismissSplash={this.dismissSplash} />
                        </main>
                    )
                    :
                    (
                        <>
                            <Header
                                schedules={this.state.schedules}
                                handleScheduleActive={this.handleScheduleActive}
                                handleAddSchedule={this.handleAddSchedule} />
                            <main>
                                <Switch>
                                    <Route exact path="/">
                                        <ViewSchedules
                                            schedules={this.state.schedules}
                                            handleAddEvent={this.handleAddEvent}
                                            handleDeleteEvent={this.handleDeleteEvent} />
                                    </Route>
                                    <Route path="/manage/:index">
                                        <ManageSchedules
                                            schedules={this.state.schedules}
                                            handleUpdateSchedule={this.handleUpdateSchedule}
                                            handleDeleteSchedule={this.handleDeleteSchedule} />
                                    </Route>

                                    <Redirect to="/" />
                                </Switch>
                            </main>
                        </>
                    )
                }
                <Footer />
            </div>
        );
    }
}